import axios from "@/axios"

const url = "/api/lote/"

class LotesService {
	async getLotesCalendar({ dateIn, dateFn }) {
		try {
			const { data } = await axios.post(`${url}get-lotes-calendar`, {
				dateIn,
				dateFn,
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async exportPdfLoteStageFour({ stageLoteId, loteId, status_stage_lote_id }) {
		try {
			const { data } = await axios.post(
				`${url}export-stage-four/pdf`,
				{ stageLoteId, loteId, status_stage_lote_id },
				{
					responseType: "blob",
				}
			)
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getLotes({ postura_id, search, start_date, end_date }) {
		try {
			const { data } = await axios.post(`${url}get-lotes`, { postura_id, search, start_date, end_date })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getStagesOfLote() {
		try {
			const { data } = await axios.post(`${url}get-stages-of-lote/get-stages`)
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getStagesRevisionInfertile({ stageLoteId, loteId, status_stage_lote_id }) {
		try {
			const { data } = await axios.post(`${url}stages/revision-infertile`, {
				stageLoteId,
				loteId,
				status_stage_lote_id,
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getStagesRevisionNacimiento({ stageLoteId, loteId, status_stage_lote_id, stageCode }) {
		try {
			const { data } = await axios.post(`${url}stages/revision-nacimiento`, {
				stageLoteId,
				loteId,
				status_stage_lote_id,
				stageCode,
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async saveStagesRevisionInfertile(response) {
		try {
			const { data } = await axios.post(`${url}stages/save-infertile`, response)
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async saveStagesUnBorn({ posturas, text, status, loteId, date_dob }) {
		try {
			const { data } = await axios.post(`${url}stages/save-un-born`, {
				posturas,
				text,
				status,
				loteId,
				date_dob,
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getFreeCintillos({ posturas, text, status }) {
		try {
			const { data } = await axios.post(`${url}cintillos-libres`, { posturas, text, status })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async saveVaccinate({ loteId, text }) {
		try {
			const { data } = await axios.post(`${url}stages/save-vaccinate`, { loteId, text })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getStagesRevisionChicks({ lote_id }) {
		try {
			const { data } = await axios.post(`${url}stages/revision-chicks`, { lote_id })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async generateChicks({ egg_lote_id, postura_id, chicks, to_update }) {
		try {
			const { data } = await axios.post(`${url}stages/upsert-chicks-by-lote`, {
				egg_lote_id,
				postura_id,
				chicks,
				to_update,
			})
			return data
		} catch (err) {
			console.log(err.response.data)
			return err.response.data
		}
	}

	async getPosturasByLoteId({ lote_id }) {
		try {
			const { data } = await axios.post(`${url}get-posturas-by-lote`, { lote_id })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async updateDobByLoteId({ dob, lote_id }) {
		try {
			const { data } = await axios.post(`${url}update-dob-lote`, { dob, lote_id })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async saveChicks({ observation, lote_id, status_id }) {
		try {
			const { data } = await axios.post(`${url}stages/save-status-stage-chicks`, {
				observation,
				lote_id,
				status_id,
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getTrackingStageByLote({ lote_id }) {
		try {
			const { data } = await axios.post(`${url}stages/tracking-by-stage`, { lote_id })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getEggsInLotes(postura_id) {
		try {
			const { data } = await axios.get(`${url}get-input-eggs-to-edit`, { params: { postura_id } })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async deleteInputEggs(egg_input_id) {
		try {
			const { data } = await axios.post(`${url}delete-input-eggs`, { egg_input_id })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async addInputEggs({ postura_id, egg_id, current_date, new_quantity }) {
		try {
			const { data } = await axios.post(`${url}add-input-eggs`, {
				postura_id,
				egg_id,
				current_date,
				new_quantity,
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async saveInputEggs({ postura_id, inputs }) {
		try {
			const { data } = await axios.post(`${url}save-inputs-eggs`, { postura_id, inputs })
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getLotesByPostura({ postura_id }) {
		try {
			const { data } = await axios.get(`${url}get-lotes-by-postura`, {
				params: { postura_id },
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async exportPdfLoteStageChicks({ lote_id }) {
		try {
			const { data } = await axios.post(
				`${url}export-stage-six/pdf`,
				{ lote_id },
				{
					responseType: "blob",
				}
			)
			return data
		} catch (err) {
			console.error(err)
		}
	}

	async getLoteAlivesCp({ id }) {
		const { data } = await axios.get(`${url}get-living-cp-by-lote/${id}`)
		return data
	}

	async getLoteAlivesCa({ id }) {
		const { data } = await axios.get(`${url}get-living-chicks-by-lote/${id}`)
		return data
	}
	async getLoteAlivesCounter({ id }) {
		const { data } = await axios.get(`${url}get-count-living-cp-and-chicks/${id}`)
		return data
	}
	async getLoteDeadsReason({ id }) {
		const { data } = await axios.get(`${url}get-reason-for-deaths-by-lote/${id}`)
		return data
	}
	async getLoteDeadsReasonGeneral(body) {
		try {
			const { data } = await axios.get(`${url}get-reason-for-deaths-general`, { params: { ...body } })
			return data
		} catch (error) {
			throw error
		}
	}
}
export default new LotesService()
